<template>
  <div>
    <div style="margin-bottom: 24px">
      <a-card :bordered="false">
        <DescriptionList title="费用信息" size="large">
          <div style="margin-top: 16px">
            <Description term="账单类型"
              ><label>{{ query.billType }}</label></Description
            >
            <Description term="选择门店"
              ><label>{{ query.facilityName }}</label></Description
            >
            <Description term="选择客户"
              ><label>{{ query.tenantName }}</label></Description
            >
            <Description term="金额"
              ><label>{{ query.amountCost }}</label></Description
            >
            <Description term="备注"
              ><label>{{ query.remark }}</label></Description
            >
          </div>
        </DescriptionList>
      </a-card>
    </div>
    <!--    <a-card title="预览费用" :bordered="false" style="margin-bottom: 24px">-->
    <!--      <a-table-->
    <!--        rowKey="id"-->
    <!--        :pagination="pagination"-->
    <!--        :columns="viewcolumns"-->
    <!--        :dataSource="viewdata"-->
    <!--      >-->
    <!--        <span slot="serial" slot-scope="text, record, index">-->
    <!--          {{ index + 1 }}-->
    <!--        </span>-->
    <!--      </a-table>-->
    <!--    </a-card>-->
    <a-card :bordered="false">
      <div>
        <label style="margin-bottom: 24px" class="listTitle">调整明细</label>
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="editcolumns"
        :dataSource="editwdata"
        :scroll="{ x: 900 }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import axios from "axios";

export default {
  name: "financeFeeDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      thisdetailId: this.$route.query.thisdetailId,
      pagination: defaultPagination(() => {}),
      viewcolumns: [
        {
          title: "客户名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "档口名称",
          dataIndex: "key",
          key: "key"
        },
        {
          title: "费用类型",
          dataIndex: "",
          key: ""
        },
        {
          title: "费用金额",
          dataIndex: "",
          key: ""
        }
      ],
      editcolumns: [
        {
          title: "调整前账单金额",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "调整后账单金额",
          dataIndex: "key",
          key: "key"
        },
        {
          title: "账单修改人",
          dataIndex: "",
          key: ""
        },
        {
          title: "账单修改时间",
          dataIndex: "",
          key: ""
        },
        {
          title: "修改原因",
          dataIndex: "",
          key: ""
        },
        {
          title: "账单审批人",
          dataIndex: "",
          key: ""
        },
        {
          title: "审批时间",
          dataIndex: "",
          key: "",
          fixed: "right",
          width: 150
        }
      ],
      editwdata: [],
      query: ""
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/finance/financeFee"
      });
    });
  },
  methods: {
    init() {
      axios({
        url:   this.$baseUrl + "/tenantCost/query",
        method: "GET",
        params: {
          id: this.thisdetailId
        }
      }).then(res => {
        if (res.data.obj) {
          res.data.obj.type = this.billType(res.data.obj.type);
          this.query = res.data.obj;
        }
      });
    },
    billType(val) {
      switch (val) {
        case 1:
          return "首期款账单";
        case 2:
          return "改造费用账单";
        case 3:
          return "退租结算账单";
        case 4:
          return "房租账单";
        case 5:
          return "经营费用账单";
        default:
          "无";
      }
    }
  }
};
</script>

<style scoped>
.ant-col-md-8 {
  width: 25%;
}
</style>
